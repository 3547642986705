import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useRouter } from 'next/router'
import Modal from 'react-modal'
import { LOGIN_PATH, SIGNUP_PATH } from '@purposity/auth'
import { Button } from '@components/Buttons/Base'
import { Spacer } from '@components/Spacer'
import { useReferral } from '@features/referral/useReferral'
import {
  createHideAction,
  useRoadblock,
} from '@features/roadblock/hooks/useRoadblock'
import * as Colors from '@themes/Colors'
import Fonts from '@themes/Fonts'

Modal.setAppElement('#__next')

export function RoadblockModal() {
  const { state, dispatch } = useRoadblock()
  const router = useRouter()
  const { setReferral } = useReferral()

  return (
    <Modal
      isOpen={state.modal}
      preventScroll
      onRequestClose={() => {
        setReferral(null)
        dispatch(createHideAction())
      }}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,.5)',
        },
        content: {
          width: '100%',
          maxWidth: 500,
          maxHeight: 500,
          border: 0,
          padding: 0,
          backgroundColor: Colors.backgroundGray,
          borderColor: Colors.backgroundGray,
          borderRadius: 10,
          borderWidth: 1,
          // margin: '0 auto',
          margin: '0',
          flexShrink: 1,
        },
      }}
    >
      <View style={styles.container}>
        <View style={authStyles.loginContainer}>
          <View style={{ marginTop: 160, marginBottom: 24 }}>
            <Text style={authStyles.headerText}>Welcome to Purposity</Text>
            <Text style={authStyles.descriptionText}>
              Let’s help you get back into your account.
            </Text>
          </View>
          <View style={{ flex: 1, flexDirection: 'row' }}>
            <Button
              format="rounded"
              onClick={() => {
                router
                  .push(
                    {
                      pathname: SIGNUP_PATH,
                    },
                    SIGNUP_PATH,
                    { shallow: true }
                  )
                  .then(() => dispatch(createHideAction()))
              }}
              title="Sign Up"
              style={{ width: 150 }}
            />
            <Spacer width={8} noShrink />
            <Button
              format="rounded"
              onClick={() => {
                router.push(
                  {
                    pathname: LOGIN_PATH,
                  },
                  LOGIN_PATH,
                  { shallow: true }
                )
                dispatch(createHideAction())
              }}
              title="Log In"
              style={{ width: 150 }}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

const authStyles = StyleSheet.create({
  loginContainer: {
    flexGrow: 1,
    alignItems: 'center',
    backgroundColor: Colors.backgroundGray,
  },
  headerText: {
    fontFamily: Fonts.type.bold,
    fontSize: Fonts.size.h4,
    color: Colors.coal,
    textAlign: 'center',
    marginVertical: 10,
  },

  descriptionText: {
    textAlign: 'center',
    color: Colors.textGray,
    fontFamily: Fonts.type.base,
    fontSize: Fonts.size.regular,
    marginHorizontal: 20,
    marginBottom: 20,

    lineHeight: 18,
  },
})

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',

    minHeight: 300,
    // margin: '0 auto',
    margin: '0',
    padding: 30,
    minWidth: 300,
  },
})
