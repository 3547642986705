import type { NextApiRequest } from 'next'
import type { NextRequest } from 'next/server'
import {
  verifyClerkToken,
  verifyClerkTokenSafe,
} from '../../methods/verifyClerkToken'
import { PurposityClaims } from '../clerk/jwt-claims.purposity'
import type {
  SignedInAuthObject,
  SignedOutAuthObject,
  AuthObject,
} from '@clerk/backend/internal'
import type { NextApiRequestCookies } from 'next/dist/server/api-utils'
import type { NextIncomingMessage } from 'next/dist/server/request-meta'
type GsspRequest = NextIncomingMessage & {
  cookies: NextApiRequestCookies
}
export type RequestLike = NextRequest | NextApiRequest | GsspRequest

export async function getFullAuth(
  req: RequestLike,
  clerkAuthObject: AuthObject
) {
  const token = getToken(req)

  const verified = await verifyClerkToken(token)

  return {
    clerk: clerkAuthObject,
    purposity: verified.purposity,
  }
}

export type FullAuth = Awaited<ReturnType<typeof getFullAuth>>

export async function getFullAuthSafe(
  req: RequestLike,
  clerkAuthObject: AuthObject
) {
  const token = getToken(req)

  const verifiedRes = await verifyClerkTokenSafe(token)
  if (!verifiedRes.success) {
    console.warn('verifyClerkTokenSafe failed', verifiedRes.error.code)
    return {
      clerk: clerkAuthObject,
      purposity: null,
    }
  } else {
    return {
      clerk: clerkAuthObject,
      purposity: verifiedRes.data.purposity,
    }
  }
}

function getToken(req: RequestLike): string | null {
  let token: string | undefined = undefined

  if ('nextUrl' in req) {
    token =
      req.cookies.get('__session')!.value ||
      req.headers.get('authorization')?.split(' ').pop()
  } else {
    token = req.cookies.__session || req.headers.authorization?.split(' ').pop()
  }

  return token || null
}

type PurpositySignedInAuthObject = SignedInAuthObject & {
  purposity: PurposityClaims
}
type PurpositySignedOutAuthObject = SignedOutAuthObject & { purposity: null }

export type PurposityAuthObject =
  | PurpositySignedInAuthObject
  | PurpositySignedOutAuthObject

export function createPurposityAuthContext(
  clerkAuthObject: AuthObject
): PurposityAuthObject {
  if (clerkAuthObject.sessionId == null) {
    return { ...clerkAuthObject, purposity: null }
  } else {
    // Clerk has already validated the token, so we can just parse it
    const purposityClaims = PurposityClaims.parse(
      clerkAuthObject.sessionClaims.purposity,
      { path: ['sessionClaims', 'purposity'] }
    )
    return { ...clerkAuthObject, purposity: purposityClaims }
  }
}
