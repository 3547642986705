/// <reference types="vitest/import-meta" />

import * as edgeConfig from '@vercel/edge-config'
import type { ClerkMiddlewareAuth } from '@clerk/nextjs/server'
import type {
  ExtendedJwtSessionClaims,
  PurposityClaims,
} from '~/lib/clerk/jwt-claims.purposity'
import { Roles } from '~/lib/clerk/authorization'

/** @see [Storage – Vercel](https://vercel.com/purposity/~/stores/edge-config/ecfg_eazqz8e3emxgvqavzqb26ljf9osk/items) (dev/prod) */
const getAdminClerkAllowedOrgs = () =>
  edgeConfig.get<string[]>('ADMIN_CLERK_ALLOWED_ORGS').then((res) => res ?? [])

export const isAllowedOrg = async (auth: ClerkMiddlewareAuth) => {
  const configProm = getAdminClerkAllowedOrgs()
  const sessionClaims = auth().sessionClaims
  const orgs = Object.keys(sessionClaims?.purposity?.orgs ?? {})

  if (import.meta.vitest) {
    // used to test the global Clerk import is propagating types correctly
    const { assertType } = import.meta.vitest
    assertType<PurposityClaims>(sessionClaims!.purposity)
    assertType<ExtendedJwtSessionClaims['org_type']>(sessionClaims?.org_type)
    // @ts-expect-error invalid key
    assertType<PurposityClaims>(sessionClaims!.rockies.orgs)
  }

  const ALLOWED_ORGS = new Set((await configProm) ?? [])
  return orgs.some((orgId) => ALLOWED_ORGS.has(orgId))
}

export const isPurposityAdmin = async (auth: ClerkMiddlewareAuth) => {
  return checkAdmin(auth().orgRole, auth().sessionClaims?.org_type)
}

export const checkAdmin = (
  role?: ClerkAuthorization['role'] | null,
  org_type?: ExtendedJwtSessionClaims['org_type'] | null
) => {
  if (role == null) return false
  if (org_type == null) return false
  return role === Roles.enum.PURPOSITY_ADMIN && org_type === 'administrative'
}
