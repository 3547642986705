export const blues = {
  '-9': '#ebfaff',
  '-8': '#d6f5ff',
  '-7': '#c2f1ff',
  '-6': '#adecff',
  '-5': '#99e7ff',
  '-4': '#85e2ff',
  '-3': '#70ddff',
  '-2': '#5cd9ff',
  '-1': '#47d4ff',
  0: '#33cfff',
  1: '#2ebae6',
  2: '#29a6cc',
  3: '#2491b3',
  4: '#1f7c99',
  5: '#1a6880',
  6: '#145366',
  7: '#0f3e4c',
  8: '#0a2933',
  9: '#051519',
}

export const white = '#ffffff'
export const backgroundGray = '#fafafd'
export const wildSand = '#f6f6f6'
export const athensGray = '#f4f5f8'
export const altWhite = '#f4f5f8'
export const COLOR_f8f9fb = '#f8f9fb'
export const neutralGray = '#ebebeb'
export const dotGray = '#cdced4'
export const purchasedGray = '#c0c1c7'
export const COLOR_d8d8d8 = '#d8d8d8'
export const textGray = '#91939c'
export const COLOR_969dad = '#969dad'
export const descriptionGray = '#646670'
export const abbyGray = '#4a4c54'
export const outerSpace = '#373a3c'
export const originalBlack = '#34353b'
export const coal = '#34353b'
export const alternateGray = '#131417'
export const realBlack = '#000000'
export const black = '#000000'
export const red = '#ff0000'
export const redAlt = 'rgba(220,53,69,.9)'
export const tomato = '#ff6347'
export const yellow = '#fbc817'
export const snapYellow = '#fffc00'
export const green = '#53ce84'
export const darkBlue = '#1c2a38'
export const seaBlue = '#1a6b86'
export const blue = '#22c4f6'
export const primary = '#33cfff'
export const actionBlue = 'rgba(20,124,250,1)'
export const fbBlue = '#3b5998'
export const purple = '#9d60c9'
export const purpleTransparent = 'rgb(157, 96, 201, 0.95)'
export const alertPink = '#fa246f'
export const transparent = 'transparent'

export const crimsonTide = '#9e1b32'
export const rockies = '#333366'
export const rockiesAlt = '#4b2e83'
export const avs = '#6F263D'
export const broncos = '#FB4F14'
export const nuggets = '#FEC524'

/** Fix Types
 * The following colors are not widely used and only included for type completeness in legacy components.
 */
export const snow = '#fffafa'
export const text = textGray
export const border = red
export const ember = '#a45c4a'
export const ricePaper = '#f9f9f9'
